import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { ZakuroApiProvider } from 'contexts/ZakuroApiContext';
import { UserProvider } from 'contexts/UserContext';

const CrawlersPage = Loadable(lazy(() => import('views/crawlers-page/crawlers')));
const NewCrawlersPage = Loadable(lazy(() => import('views/crawlers-page/crawler-new')));
const CrawlersDetailPage = Loadable(lazy(() => import('views/crawlers-page/crawler-details')));
const CrawlersCodePage = Loadable(lazy(() => import('views/crawlers-page/code')));
const ItemsPage = Loadable(lazy(() => import('views/crawlers-page/items')));
const ItemDetailPage = Loadable(lazy(() => import('views/crawlers-page/item-details')));
const CrawlErrorsPage = Loadable(lazy(() => import('views/crawlers-page/crawl-errors')));
const IndexCrawlErrorDetailPage = Loadable(lazy(() => import('views/crawlers-page/index-crawl-error-details')));
const ItemCrawlErrorDetailPage = Loadable(lazy(() => import('views/crawlers-page/item-crawl-error-details')));
const SyncsPage = Loadable(lazy(() => import('views/crawlers-page/syncs')));
const SyncDetailPage = Loadable(lazy(() => import('views/crawlers-page/sync-details')));
const NewSyncPage = Loadable(lazy(() => import('views/crawlers-page/sync-new')));
const SandboxPage = Loadable(lazy(() => import('views/sandbox-page')));

// Storybooks
const GridExamplePage = Loadable(lazy(() => import('views/storybooks/grid-example')));
const CardExamplePage = Loadable(lazy(() => import('views/storybooks/card-example')));
const TableExamplePage = Loadable(lazy(() => import('views/storybooks/table-example')));
const TypographyExamplePage = Loadable(lazy(() => import('views/storybooks/typography-example')));
const FormExamplePage = Loadable(lazy(() => import('views/storybooks/form-example')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <ZakuroApiProvider>
                <UserProvider>
                    <MainLayout />
                </UserProvider>
            </ZakuroApiProvider>
        </AuthGuard>
    ),
    children: [
        {
            path: '/crawlers',
            element: <CrawlersPage />
        },
        {
            path: '/crawlers/new',
            element: <NewCrawlersPage />
        },
        {
            path: '/crawlers/:crawler_id',
            element: <CrawlersDetailPage />
        },
        {
            path: '/crawlers/:crawler_id/code',
            element: <CrawlersCodePage />
        },
        {
            path: '/crawlers/:crawler_id/items',
            element: <ItemsPage />
        },
        {
            path: '/crawlers/:crawler_id/items/:id',
            element: <ItemDetailPage />
        },
        {
            path: '/crawlers/:crawler_id/integrations',
            element: <SyncsPage />
        },
        {
            path: '/crawlers/:crawler_id/integrations/new',
            element: <NewSyncPage />
        },
        {
            path: '/crawlers/:crawler_id/integrations/:id',
            element: <SyncDetailPage />
        },
        {
            path: '/crawlers/:crawler_id/crawl-errors',
            element: <CrawlErrorsPage />
        },
        {
            path: '/crawlers/:crawler_id/index-crawl-errors/:id',
            element: <IndexCrawlErrorDetailPage />
        },
        {
            path: '/crawlers/:crawler_id/item-crawl-errors/:id',
            element: <ItemCrawlErrorDetailPage />
        },
        {
            path: '/sandbox',
            element: <SandboxPage />
        },
        {
            path: '/storybooks/grid-example',
            element: <GridExamplePage />
        },
        {
            path: '/storybooks/card-example',
            element: <CardExamplePage />
        },
        {
            path: '/storybooks/table-example',
            element: <TableExamplePage />
        },
        {
            path: '/storybooks/typography-example',
            element: <TypographyExamplePage />
        },
        {
            path: '/storybooks/form-example',
            element: <FormExamplePage />
        }
    ]
};

export default MainRoutes;
