import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import defaultMenuItem from 'menu-items/default-menu-items';
import crawlerMenuItem from 'menu-items/crawler-menu-items';
import { useParams } from 'react-router-dom';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const params = useParams();
    const menuItem = params.crawler_id ? crawlerMenuItem(params.crawler_id) : defaultMenuItem;
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
