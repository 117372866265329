import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const NotFoundPage = Loadable(lazy(() => import('views/not-found')));

// ==============================|| MAIN ROUTING ||============================== //

const NotFoundRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/*',
            status: 400,
            element: <NotFoundPage />
        }
    ]
};

export default NotFoundRoutes;
