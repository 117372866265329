import React, { createContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';

interface UserInterface {
    userId: number;
    email: string;
}
const UserContext = createContext<UserInterface>({ userId: 0, email: 'dummy@gmail.com' });
const ME = gql`
    query ME($auth0_user_id: String!) {
        users(where: { auth0_user_id: { _eq: $auth0_user_id } }) {
            id
            auth0_user_id
            email
        }
    }
`;

export const UserProvider = ({ children }: { children: React.ReactElement }) => {
    const [initializeState, setInit] = useState('pending');
    const [userData, setUserData] = useState({ id: 0, email: 'dummy@gmail.com' });
    const { loading, error, data } = useQuery(ME, {
        skip: initializeState === 'finished',
        variables: {
            auth0_user_id: 'google-oauth2|102264382976057090271'
        }
    });

    if (loading) {
        return <CircularProgress />;
    }
    if (error) {
        console.log(error);
    }
    if (data && initializeState === 'pending') {
        setUserData({ ...data.users[0] });
        setInit('finished');
    }
    if (initializeState === 'finished') {
        return <UserContext.Provider value={{ userId: userData.id, email: userData.email }}>{children}</UserContext.Provider>;
    }
    return <CircularProgress />;
};

export default UserContext;
