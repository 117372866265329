import React, { createContext } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import useAuth from '../hooks/useAuth';
import { ZAKURO_API } from 'config';

const ZakuroApiContext = createContext;

const httpLink = createHttpLink({
    uri: ZAKURO_API.endpoint
});

export const ZakuroApiProvider = ({ children }: { children: React.ReactElement }) => {
    const { getAccessToken } = useAuth();

    const authLink = setContext(async (_, { headers }) => {
        const token = await getAccessToken();
        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : ''
            }
        };
    });
    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ZakuroApiContext;
