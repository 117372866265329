// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { IconMailbox, IconCode } from '@tabler/icons';

// constant
const icons = {
    IconMailbox,
    IconCode
};

const shared = {
    id: 'shared',
    type: 'group',
    children: [
        {
            id: 'sandbox',
            title: 'サンドボックス',
            type: 'item',
            url: '/sandbox',
            icon: icons.IconCode,
            target: true,
            breadcrumbs: false
        },
        {
            id: 'inquiry',
            title: 'お問い合わせ',
            type: 'item',
            url: 'https://twitter.com/ogontaro',
            icon: icons.IconMailbox,
            breadcrumbs: false,
            external: true,
            target: true
        }
    ]
};

export default shared;
