import shared from '../shared';
import crawler, { crawlerForBreadcrumbs } from './crawler';
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const crawlerItems = (crawler_id: string): { items: NavItemType[] } => ({
    items: [crawler(crawler_id), shared]
});

export const crawlerBreadcrumbsItems = (crawler_id: string): { items: NavItemType[] } => ({
    items: [crawlerForBreadcrumbs(crawler_id), shared]
});

export default crawlerItems;
