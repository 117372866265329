// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconSettings, IconFileCode, IconDatabase, IconAlertCircle, IconAffiliate } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconFileCode,
    IconSettings,
    IconDatabase,
    IconAlertCircle,
    IconAffiliate
};

const crawlerChildren = (crawler_id: string) => [
    {
        id: 'settings',
        title: '設定',
        type: 'item',
        url: `/crawlers/${crawler_id}`,
        icon: icons.IconSettings
    },
    {
        id: 'code',
        title: 'クロールコード',
        type: 'item',
        url: `/crawlers/${crawler_id}/code`,
        icon: icons.IconFileCode
    },
    {
        id: 'items',
        title: 'アイテム',
        type: 'item',
        url: `/crawlers/${crawler_id}/items`,
        icon: icons.IconDatabase
    },
    {
        id: 'errors',
        title: 'エラーログ',
        type: 'item',
        url: `/crawlers/${crawler_id}/crawl-errors`,
        icon: icons.IconAlertCircle
    },
    {
        id: 'integration',
        title: '外部連携',
        type: 'item',
        url: `/crawlers/${crawler_id}/integrations`,
        icon: icons.IconAffiliate
    }
];

const crawler = (crawler_id: string) => ({
    id: 'crawler',
    type: 'group',
    children: crawlerChildren(crawler_id)
});

export const crawlerForBreadcrumbs = (crawler_id: string) => ({
    id: 'crawler',
    type: 'group',
    children: [
        {
            id: 'crawler-for-breadcrumbs',
            title: 'クローラー',
            type: 'collapse',
            icon: icons.IconBrandChrome,
            url: `/crawlers/${crawler_id}`,
            children: crawlerChildren(crawler_id)
        }
    ]
});

export default crawler;
